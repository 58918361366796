import { Container, Grid, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { apiURL } from "api";
import TagApi from "api/tag";
import { convertFromRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Verify from "views/Verify";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    height: "100svh",
    overflowY: "scroll",
    backgroundColor: palette.background.verify,
    transition: "background-color 1s",
    display: "grid",
    placeItems: "center",
  },
  preloaderRoot: {
    textAlign: "center",
  },
  container: {
    paddingBottom: 44,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

function VerifyLayout() {
  const classes = useStyles();
  const [customization, setCustomization] = useState({});
  const [details, setDetails] = useState();
  const [expired, setExpired] = useState();
  const [txId, setTxId] = useState();
  const [verifiedBusiness, setVerifiedBusiness] = useState(true);
  const [verifiedTrademark, setVerifiedTrademark] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState({});
  const [images, setImages] = useState();
  const [documents, setDocuments] = useState();
  const [productJourney, setProductJourney] = useState();
  const [delivered, setDelivered] = useState();
  const [productDetailCustomization, setProductDetailCustomization] = useState();
  const [logEnded, setLogEnded] = useState();
  const [blockchainVerified, setBlockchainVerified] = useState();
  const [loading, setLoading] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState();
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    let timer, progress;
    const query = new URLSearchParams(window.location.search);
    const address = query.get("a") || query.get("address");
    const p = query.get("p");
    if (address) {
      TagApi.verify(address, p)
        .then(res => {
          const {
            verified,
            transaction,
            expired,
            verifiedBusiness,
            verifiedTrademark,
            customization,
            sensorsData,
            delivered,
            logisticLoggingEnded,
            logisticSensorData,
            productDetailOrder,
            verifyStatus,
          } = res;
          customization &&
            setCustomization(
              customization.additions
                ? {
                    ...customization,
                    additions: customization.additions.map(i =>
                      i.type === "text" ? { ...i, text: i.text && EditorState.createWithContent(convertFromRaw(i.text)) } : i
                    ),
                  }
                : customization
            );

          setVerificationStatus(verifyStatus);
          setVerifiedBusiness(verifiedBusiness);
          setVerifiedTrademark(verifiedTrademark);
          setDelivered(delivered);
          setLogEnded(logisticLoggingEnded);
          setExpired(expired);
          setBlockchainVerified(verified);
          setProductDetailCustomization({
            order: productDetailOrder,
            customSKUName: customization?.customSKUName,
            customSKU: customization?.customSKU,
            breakLineChar: customization?.breakLineChar,
          });
          if (!customization?.hideDefaultButton && verified && !expired && verifiedBusiness && verifiedTrademark) {
            TagApi.redirect(address).then(res => {
              if (res.ok) {
                setRedirectUrl(`${res.verify_url}?a=${address}`);
              }
            });
          }
          if (transaction?.message?.type === 0) {
            const blockchainPayload = JSON.parse(transaction.message.payload);
            setTxId(transaction?.transactionInfo?.id);
            if (blockchainPayload.type === 500) {
              const { file, imgs, document, certs, ...details } = blockchainPayload.payload;
              const custom_id_label = Object.keys(details).find(i => typeof details[i] === "object");
              let custom_id;
              setProductJourney({ logisticSensorData });
              if (custom_id_label && Object.keys(custom_id_label).length > 0) {
                custom_id = Object.keys(details[custom_id_label])[0];
                setProductJourney(_ => {
                  return { ..._, custom_id_label, custom_id, sensorAnswers: details[custom_id_label][custom_id], sensorsData };
                });
                delete details[custom_id_label];
              }
              setDetails(!sensorsData?.length && custom_id ? { ...details, [custom_id_label]: custom_id } : details);
              const images = imgs || file;
              if (images) {
                const { hash, ext } = images;
                if (hash) setImages([`${apiURL}/files/${hash}${ext}`]);
                else {
                  setImages(images.map(({ hash, ext }) => `${apiURL}/files/${hash}${ext}`));
                }
              }
              const certificates = certs || document;
              if (certificates) {
                if (Array.isArray(certificates))
                  setDocuments(
                    certificates.map(({ hash, ext, name }) => {
                      return { name, link: `${apiURL}/files/${hash}${ext}` };
                    })
                  );
                else {
                  const { hash, ext, name } = certificates;
                  setDocuments([{ name, link: `${apiURL}/files/${hash}${ext}` }]);
                }
              }
            }
          } else if (typeof transaction === "object" && transaction !== null) {
            setDetails({});
          }
        })
        .finally(() => {
          progress = setInterval(() => {
            setProgress(prevProgress => (prevProgress >= 100 ? 100 : prevProgress + 1));
          }, 9.5);
          timer = setTimeout(() => setLoading(false), 1300);
        });

      return () => {
        clearTimeout(timer);
        clearTimeout(progress);
      };
    }
  }, []);
  return (
    <div className={classes.root} style={{ backgroundColor: !loading && customization?.background_color }}>
      <Container maxWidth="xs" className={classes.container}>
        {loading ? (
          <Grid item className={classes.preloaderRoot}>
            <img alt="preloader" src={require("assets/gif/preloader.gif")} />
            <LinearProgress variant="determinate" value={progress} className={classes.progress} />
            <Typography variant="h1">{progress < 50 ? t("Messages.Fetching data from blockchain") : t("Messages.Verifying")}</Typography>
          </Grid>
        ) : (
          <Verify
            customization={{
              ...customization,
              logo: customization?.logo && `${apiURL}/files/${customization?.logo?.hash}${customization?.logo?.ext}`,
            }}
            details={details}
            productDetailCustomization={productDetailCustomization}
            documents={documents}
            expired={expired}
            images={images}
            redirectUrl={redirectUrl}
            verified={blockchainVerified}
            verifyStatus={verificationStatus}
            verifiedBusiness={verifiedBusiness}
            verifiedTrademark={verifiedTrademark}
            delivered={delivered}
            logEnded={logEnded}
            productJourney={productJourney}
            txId={txId}
          />
        )}
      </Container>
    </div>
  );
}

export default VerifyLayout;
