import React from "react";
import { Box } from "@material-ui/core";

const statusMap = {
  PENDING: {
    label: "Pending Attestation",
    style: {
      backgroundColor: "#FFF8E1", // Light Yellow background
      color: "#FFC107", // Darker Yellow text
      border: "2px solid #FFC107", // Yellow border
    },
  },
  SUCCESS: {
    label: "Verified",
    style: {
      backgroundColor: "#E8F5E9", // Light Green background
      color: "#4CAF50", // Dark Green text
      border: "2px solid #4CAF50", // Green border
    },
  },
  FAILED: {
    label: "Failed",
    style: {
      backgroundColor: "#FFEBEE", // Light Red background
      color: "#F44336", // Dark Red text
      border: "2px solid #F44336", // Red border
    },
  },
};

export const VerificationStatusChip = ({ status }) => {
  const currentStatus = statusMap[status] || {
    label: "Unknown",
    style: { backgroundColor: "#F5F5F5", color: "#9E9E9E", border: "2px solid #9E9E9E" }, // Gray fallback
  };

  return (
    <Box
      style={{
        ...currentStatus.style,
        padding: "2px 12px",
        borderRadius: "20px",
        fontSize: "10px",
        fontWeight: "600",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        textTransform: "uppercase",
        letterSpacing: "0.5px",
      }}
    >
      {currentStatus.label}
    </Box>
  );
};
